<template>
  <div class="ma-auto" />
</template>
<script>
  import axios from 'axios'
  import { mapGetters, mapActions } from 'vuex'
  import { getToken, getUserPersonID, get } from '../../../worker/worker-api'
  export default {
    name: 'Callback',
    metaInfo: {
      // title will be injected into parent titleTemplate
      title: 'Profil',
    },
    methods: {
      getProfile (Ai_UserID, As_TargetRole) {
        let Lo_CurrentPlanningPeriod;
        return get(`${process.env.VUE_APP_SMT_API_URL}/user/${Ai_UserID}`, {})
          .then(response => {
            if (response.status === 401) {
              this.signOut()
              this.$smt.logout()
              return
            }
            this.setRoles(response.a_Roles);
            if(response.a_Roles.length > 0) this.setCurrentRole(response.a_Roles[0]);
            this.setPlanningPeriods(response.a_PlanningPeriods)
            if(As_TargetRole !== undefined)
              Lo_CurrentPlanningPeriod = this.$smt.findCurrentPeriod(response.a_PlanningPeriods, As_TargetRole);
            else
              Lo_CurrentPlanningPeriod = this.$smt.findCurrentPeriod(response.a_PlanningPeriods, response.a_Roles[0].s_Role);
            this.setCurrentPlanningPeriod(Lo_CurrentPlanningPeriod)
            this.setCurrentExamEvent(response.o_CurrentExamEvent)
            this.setProfile(response)
          })
          .catch(error => {
          })
      },
      ...mapActions([
        'login',
        'setRoles',
        'setProfile',
        'setPlanningPeriods',
        'setCurrentRole',
        'setCurrentPlanningPeriod',
        'setCurrentExamEvent',
        'signOut']),
    },
    async mounted () {
      const s_Url = `${process.env.VUE_APP_OAUTH_AUTH_SERVER}/oauth/token`
      const o_Params = {
        grant_type: 'authorization_code',
        client_id: process.env.VUE_APP_OAUTH_CLIENT_ID,
        client_secret: process.env.VUE_APP_OAUTH_CLIENT_SECRET,
        redirect_uri: process.env.VUE_APP_OAUTH_CLIENT_REDIRECT,
        code: this.$route.query.code,
      }
      if (await getToken(s_Url, o_Params)) {
        const i_UserID = await getUserPersonID();
        const s_TargetUrl = localStorage.getItem('targetUrl');
        const s_TargetRole = this.$smt.getRoleFromUrl(s_TargetUrl);
        this.login(i_UserID)
        if (s_TargetUrl != `/${this.$i18n.locale}/admin-ms/sa`) await this.getProfile(i_UserID, s_TargetRole)
        if (s_TargetUrl === null) this.$router.push('/')
        else this.$router.push(s_TargetUrl).catch(() => {})
      }
    },
  }
</script>
